import service from "@/utils/request";
import http from "@/utils/dmsrequest";
//获取订单详情
export function getOrderDetail(id) {
  return service({
    method: "get",
    url: "/order/orderLine.htm",
    params: {
      id: id
    }
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}