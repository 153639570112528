import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import {
  getOrderDetail
} from './api'
import {
  cancleOrderAll,
  cancleOrderLine
} from '../api'
import Process from '../components/Process.vue'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {
  addCart
} from "@/views/order/api";
import { findSalesOrderByBhoOrder, queryUserByPhoneAndShopCode, selectServiceFree } from "@/views/order/details/api";

export default {
  name: "purchaseOrderDetail",
  components: {
    ProductItem,
    Process,
    Btn
  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/order/cis",
          name: "order",
          title: "采购订单列表"
        },
        {
          path: "/order/detailsEngineering",
          name: "detail",
          title: "订单详情"
        }
      ],
      orderDetail: {
        orderHeader: {},
      },
      pageLoadFlag: false,
      bg: bg,
      visible: false,
      id: '',
      productGroupFlag:'',
      groupCanCancel:'',
      bgimg: require("././../../../assets/noticeList/编组 13@3x.png"),
      isTrue:false,
      ids:[],
      nums:[],
      serviceFeeTotal:0,// 服务费总计
      relationSalesOrder: [], // 关联零售订单号
      relationSalesOrderId: [], // 关联零售订单号id
      intendedUserId:'',//查看用户档案id
    };
  },
  computed: {
    totalProd: function () {
      let total = 0
      if(this.productGroupFlag == 'Y') {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(proItem => {
            proItem.data.forEach(item=>{
              total += item.qty
            })
          
          })
        }
      } else {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(item => {
            total += item.qty
          })
        }
      }
      return total

    },
    totalPrice: function () {
      let total = 0
      if(this.productGroupFlag == 'Y') {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(proItem => {
            proItem.data.forEach(item=>{
              total += item.qty * (item.billPrice * 100) / 100
            })
          
          })
        }
      } else {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(item => {
            total += item.qty * (item.billPrice * 100) / 100
          })
        }
      }
     
      return total
    },
    totalLoadVolume: function () {
      let total = 0
      if(this.productGroupFlag == 'Y') {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(proItem => {
            proItem.data.forEach(item=>{
              total += item.qty * Math.max(item.loadVolume, 0)
            })
          
          })
        }
      } else {
        if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
          this.orderDetail.orderLines.forEach(item => {
            total += item.qty * Math.max(item.loadVolume, 0)
          })
        }
      }
     
      return total
    },
    totalAdvancePrice: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.advancePrice
        })
      }
      return total
    },

  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    } else {
      // this.getDetail(15555629683)
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods: {
    // 看用户档案
    gotoUser(){
      let data = {
        shopCode: this.orderDetail.orderHeader.shopCode, // 门店id
        phone: this.orderDetail.orderHeader.contactPhone // 手机号
      }
      queryUserByPhoneAndShopCode(data).then(res=>{
        if (res.data.code == 0 ) {
          this.intendedUserId = res.data.data != '0' ? res.data.data : ''
        }
      })
    },
    gotoUserDeatil() {
      this.$router.push({
        path: "/IntentionDetail",
        query: {
          id: this.intendedUserId
        }
      });
    },
    // 查看服务费
    gotoFee(item){
      this.$router.push({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item.feeCode
        }
      });
    },
    // 获取关联零售订单号
    getOrder(){
      findSalesOrderByBhoOrder({bhoOrder: this.orderDetail.orderHeader.orderCode}).then((result)=>{
        let data = JSON.parse(result.data)
        // salesId: 1488298309944
        // salesOrderNum: "S20113832308020030"
        if(data.code == 0){
          this.relationSalesOrder = data.data.map(obj => {
            return obj.salesOrderNum;
          });
          this.relationSalesOrderId = data.data.map(obj => {
            return obj.salesId;
          });
        }

      })
    },
    gotoReatilDetail(id){
      this.$router.push({
        path: '/retailOrder/detailRetail',
        query: { id: this.relationSalesOrderId[id]}
      });
      return
    },
    toDetail(proCode, orgId) {
      this.$router.push({
        path: "/product/detail",
        query: { productCode: proCode, orgId: orgId },
      });
    },
    // 更改搜索数据框
    dataChange(goodsItem,e) {
      goodsItem.ischerk = true
      this.$forceUpdate()
    },
    // 更改多选框数据
    onChange(gooItm,e) {
      gooItm.ischerk = e.target.checked
      this.$forceUpdate()
    },
    // 组合购数据重构
    changeData(items) {
      var map = {},
        dest = [];
      for (var i = 0; i <  items.length; i++) {
        var ai =  items[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return  dest;
    },
    // 再来一单
    againOrder() {
      let carts = this.id;
      let versions = "";
      let orgAndGroup = this.orderDetail.orderHeader.orgId + '-' + this.orderDetail.orderHeader.matklId;
      let purchaseType = "";
      // versions = versions.substring(0,versions.length-1)
      if (this.orderDetail.orderHeader.orderType == "应急订单") {
        purchaseType = "1";
      } else if (this.orderDetail.orderHeader.orderType == "常规订单") {
        purchaseType = "2";
      }
      this.$router.push({
        path: "/confirm/orderAgain",
        query: {
          carts: carts,
          versions: versions,
          orgAndGroup: orgAndGroup,
          purchaseType: purchaseType,
        },
      });
    },
    formatPrice(price) {
      // if (Math.floor(price) === price) {
      //   return price
      // } else {
      return (Math.round(new Number(price) * 1000) / 1000).toFixed(2)
      // }
    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      }
      addCart(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("加入购物车成功");
        } else {
          this.$message.warning(res.data.msg);
        }
      }).catch(error => {
        this.$message.warning('加入购物车失败');
        console.log('加入购物车', error);
      })
    },
    async getDetail(id) {
      this.pageLoadFlag = true
      getOrderDetail(id).then(res => {
        this.orderDetail = res.data
        this.productGroupFlag = res.data.orderHeader.productGroupFlag
        this.groupCanCancel =  res.data.orderHeader.groupCanCancel
        let itemIds = this.orderDetail.orderLines.map(it=>it.id)

        this.getOrder()
        this.gotoUser()
        if ((this.orderDetail.orderHeader.deliveryTypeId ==  502004 )&&  this.orderDetail.orderHeader.serviceFeeCode){
          let data  = {
            orderType:1,
            orderItemIds: itemIds
          }
          selectServiceFree(data).then((resA)=>{
            this.serviceFeeTotal = resA.data.data.total
            // 非组合购
            if(this.productGroupFlag == 'Y'){
              res.data.orderLines.forEach(item=>{
                if(item.data.length>0){
                  item.data.forEach(itemProduct=>{
                    resA.data.data.items.forEach(itemTwo=>{
                      if(itemProduct.id == itemTwo.orderItemId) {
                        this.$nextTick(()=> {
                          this.$set(itemProduct,'serviceFee',itemTwo.serviceFee)
                        })
                      }
                    })
                  })
                }

              })
            }else {
              res.data.orderLines.forEach(item=>{
                resA.data.data.items.forEach(itemTwo=>{
                  if(item.id == itemTwo.orderItemId) {
                    this.$nextTick(()=> {
                      this.$set(item,'serviceFee',itemTwo.serviceFee)
                    })
                  }
                })
              })

            }
          })
        }

        if(this.productGroupFlag == 'Y') {
          this.orderDetail.orderLines = this.changeData(this.orderDetail.orderLines)
          this.orderDetail.orderLines.forEach(proItem => {
            proItem.forEach(item => {
              item.ischerk = false
              item.cherkNum = 0
            })
          })
        }
        this.orderDetail.orderLines.forEach(item => {
          if (item.billPrice.toString().indexOf('.') == '-1') {
            item.billPrice = Number(item.billPrice).toFixed(2)
          }
          if (item.basePrice.toString().indexOf('.') == '-1') {
            item.basePrice = Number(item.basePrice).toFixed(2)
          }
        })
      }).catch(err => {
      }).finally(() => {
        this.pageLoadFlag = false
      })
    },
         // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      let standardValArr = [] // 每个组实际要求数量
      let currValArr = [] // 每个组购买总数
      let multipleArr = [] // 每个组购买总数与实际要求数量成比例
      // proItem.dataArr.forEach((aaitem) => {
        proItem.orderLines.forEach(val=>{
        val.numOrder = 0
        val.data.forEach(item => {
          if(item.ischerk) {
            val.numOrder += item.cherkNum
          }
        })
        standardValArr.push(val.packageNum)
        currValArr.push(val.numOrder)
        multipleArr.push(val.numOrder % val.packageNum)
       })
       
      // })
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false
        }
      }
      let multiple = currValArr[0] / standardValArr[0] //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false
        }
      }
      return true
    },
     // 取消整单
     cancleOrderAllezuhe(orderType, statusCode, id,dataItem) {
      this. ids = []
      this. nums = []
      this.isTrue = false
      //取消整单
     
    dataItem.orderLines.forEach(item=>{
      item.cherkNum = 0
      item.packageNum = 0
      item.data.forEach(aaa=>{
        item.packageNum = aaa.packageNum
        if(aaa.ischerk) {
          item.cherkNum += aaa.cherkNum
          this.ids.push(aaa.id)
          this.nums.push(aaa.cherkNum)
        }
      })

      this. isTrue = this.checkCombinationPurchase(dataItem)
    })
    
      if(this.isTrue) {
        let obj = {
          orderTypeId: orderType,
          orderStatusCode: statusCode,
          id: id,
          type: 1,
          ids:this.ids,
          nums:this.nums
        };
        this.cancelOrder(obj);
      } else {
        this.$message.warning('取消数量不符合组合购比例，请重新选择')
      }
    },
    cancel(type, item) {
      this.ordertypeCancel = type
      this.itemId = item && item.id
      this.cancelOrder(this.orderDetail.orderHeader)
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: '取消提醒',
          content: "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true
          },
          onCancel() {

          }
        });
      } else {
        this.visible = true
      }
    },
    getReason(value) {
      this.cancelReason = value
      const id = (this.ordertypeCancel == 1 || this.productGroupFlag == 'Y') ? this.$route.query.id : this.itemId
      this.cancleSubmit(id, this.ordertypeCancel)
    },
    cancleSubmit(id, type) {
      this.pageLoadFlag = true
      let obj = {
        id: id,
        cancelReason: this.cancelReason,
        ids:this.ids.join(','),
        nums:this.nums.join(',')
      }
      if (type == 2) {
        cancleOrderLine(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          } else {
            this.$message.success('订单取消失败',res.data.msg)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      } else {
        cancleOrderAll(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          } else {
            this.$message.success('订单取消失败',res.data.msg)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    getPrice(item) {
      return ((item.billPrice || 0) * 100) * (item.qty || 0) / 100
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    gotoProcess() {
      // this.$router.push({
      //   path:'/order/progress',
      //   query:{
      //     id:this.$route.query.id
      //   }
      // })
      let routeUrl = this.$router.resolve({
        path: '/order/progress',
        query: {
          id: this.$route.query.id
        }
      });
      window.open(routeUrl.href, "_blank");
    }
  }
};